/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://gistcdn.githack.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css');
@font-face {
  font-family: 'Gilroy-light';
  src: url('/assets/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src:url('/assets/fonts/Glory-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Gilroy-Regular';
  src:url('/assets/fonts/Glory-Regular.ttf') format('truetype');
  font-weight: bold;
}
:root{
    --d9: #D9D9D9;
    --sec-clr:#00DF9A;
}

body {
    font-family: 'Gilroy' !important;
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #050505;
    // background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(22,33,44,1) 20%, rgba(5,5,5,1) 21%, rgba(22,33,44,1) 70%, rgba(13,16,19,1) 72%, rgba(17,13,19,1) 92%, rgba(0,0,0,1) 100%) !important;
    margin: 0;
    // zoom: 0.8;
}

h1, h2, h3, h4, h5, h6 {
    margin: 8px 0;
}

p {
    margin: 0;
}

.spacer {
    flex: 1;
}

.toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #1976d2;
    color: white;
    font-weight: 600;
}

.toolbar img {
    margin: 0 16px;
}

.toolbar #twitter-logo {
    height: 40px;
    margin: 0 8px;
}

.toolbar #youtube-logo {
    height: 40px;
    margin: 0 16px;
}

.toolbar #twitter-logo:hover,
.toolbar #youtube-logo:hover {
    opacity: 0.8;
}

.content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
    column-gap: 10px;
}

svg.material-icons {
    height: 24px;
    width: auto;
}

svg.material-icons:not(:last-child) {
    margin-right: 8px;
}

.card svg.material-icons path {
    fill: #888;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
    
}

.card {
    all: unset;
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: #fafafa;
    height: 40px;
    width: 200px;
    margin: 0 8px 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 24px;
}

.card-container .card:not(:last-child) {
    margin-right: 0;
}

.card.card-small {
    height: 16px;
    width: 168px;
}

.card-container .card:not(.highlight-card) {
cursor: pointer;
}

.card-container .card:not(.highlight-card):hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}

.card-container .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
}

.card.highlight-card {
    background-color: #1976d2;
    color: white;
    font-weight: 600;
    border: none;
    width: auto;
    min-width: 30%;
    position: relative;
}

.card.card.highlight-card span {
    margin-left: 60px;
}

svg#rocket {
    width: 80px;
    position: absolute;
    left: -10px;
    top: -24px;
}

svg#rocket-smoke {
    height: calc(100vh - 95px);
    position: absolute;
    top: 10px;
    right: 180px;
    z-index: -10;
}

a, a:visited, a:hover {
    color: #1976d2;
    text-decoration: none;
}

a:hover {
    color: #125699;
}

.terminal {
    position: relative;
    width: 80%;
    max-width: 600px;
    border-radius: 6px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: hidden;
    background-color: rgb(15, 15, 16);
}

.terminal::before {
    content: "\2022 \2022 \2022";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: rgb(58, 58, 58);
    color: #c2c3c4;
    width: 100%;
    font-size: 2rem;
    line-height: 0;
    padding: 14px 0;
    text-indent: 4px;
}

.terminal pre {
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
}

.circle-link {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 8px;
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 1s ease-out;
}

.circle-link:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    line-height: 20px;
}

footer a {
    display: flex;
    align-items: center;
}

.github-star-badge {
    color: #24292e;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid rgba(27,31,35,.2);
    border-radius: 3px;
    background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
    margin-left: 4px;
    font-weight: 600;
}

.github-star-badge:hover {
    background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
    border-color: rgba(27,31,35,.35);
    background-position: -.5em;
}

.github-star-badge .material-icons {
    height: 16px;
    width: 16px;
    margin-right: 4px;
}

svg#clouds {
    position: fixed;
    bottom: -160px;
    left: -230px;
    z-index: -10;
    width: 1920px;
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
    .card-container > *:not(.circle-link) ,
    .terminal {
        width: 100%;
    }

    .card:not(.highlight-card) {
        height: 16px;
        margin: 8px 0;
    }

    .card.highlight-card span {
        margin-left: 72px;
    }

    svg#rocket-smoke {
        right: 120px;
        transform: rotate(-5deg);
    }
}

    .maxContainerWidth{
        width: 100%;
        max-width: 1440px;
        margin: auto;
    }
   
    @media screen and (max-width: 1200px) {
        .maxContainerWidth{
            padding: 0 20px;
        }
    }

@media screen and (max-width: 575px) {
    svg#rocket-smoke {
        display: none;
        visibility: hidden;
    }
}
.bg-green{
    background-color: #00DF9A !important;
}

.border-light{
    // border: 0.2px solid #0A956A;
    &::after{
        content: "";
        width: 100%;
        // max-width: 1440px;
        background-color: #515151;
        height: 1px;
        position: absolute;
        opacity: 0.4;
        margin: auto;
        left: 50%;
        transform: translateX(-50%);
    }
}

.button-border{
    margin: auto 10px;
    width: 94px;
    height: 44px;
    // border: 1px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover{
        // .button{
        //     margin: -5px -2px;
        // }
        // border-color: #00DF9A !important;
    }
}
.button{
    margin: auto 10px;
    cursor: pointer;
    height: 42px;
    width: 100%;
    border: none;
    background: #FFFFFF;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #050505;
}
.flex-space-evenly{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    row-gap: 10px;
}

.flex-space-around{
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: space-around;
    row-gap: 10px;
}
.flex-space-between{
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 10px;
}
.flex-column-space-evenly{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    column-gap: 10px;
}

.flex-column-space-around{
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: space-around;
    column-gap: 10px;
}

.flex-wrap-space-evenly{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;
}

.flex-wrap-space-around{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 10px;
    column-gap: 10px;

}

.text-center{
    align-items: center;
}

.w-25{
    width: 25% !important;
    padding: 15px;
}
.w-50{
    width: 50% !important;
    padding: 15px;
}
.w-75{
    width: 75% !important;
    padding: 15px;
}
.w-100{
    width: 100% !important;
    padding: 15px;
}


.m-auto{
    margin: auto;
}

.m15{
    margin: 15px;
}

.logo{
    width: 172px;
}

.bg-black-gradient{
    background: linear-gradient(180deg, #050505 0%, #11151A 41.98%, #11151A 73.23%, #121A22 100%);
}

.heading-f50{
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
}
.overlay{
    height: 200vh;
    width: 100%;
    position: fixed;
    background: rgba(5, 5, 5, 0.6);
    top: 0;
}
.text-gradient{
    text-transform: uppercase;
        background: linear-gradient(90deg, #00DF9A 0%, rgba(2, 223, 156, 0.993438) 35.11%, rgba(90, 219, 237, 0.87) 66.2%, rgba(90, 219, 237, 0.65) 117.05%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        // width: fit-content;
}

// Media queries 
@media screen and (max-width:1200px) {
    .button-border {
        border: 0;
        position: relative;
        
        & .border{
            display: none;
            position: absolute;
            top: 7px;
            left: -5px;
            width: 100%;
            height: 100%;
            border: 1px solid #00DF9A;
            border-radius: 10px;

            }
    }
}
@media screen and (max-width:768px) {
    .logo{
    width: 92px;
}
}
.btn {
    font-weight: 600;
    font-size: 14px;
    color: #00df9a;
    background-color: #000000;
    padding: 8px 16px;
    border: solid #00df9a 1px;
    box-shadow: rgb(0, 0, 0) -50px -50px 0px -50px;
    border-radius: 50px;
    transition: 696ms;
    transform: translateY(0);
    cursor: pointer;
    text-align: center;
    // margin: auto;
    line-height: 18px;
}

.btn:hover {

    transition: 696ms;
    transform: translateY(-0px);
    background-color: #00df9a;
    color: #000000 !important;
    font-weight: 700;
    transition: all 0.2s;
}
.btn-sec {
    font-weight: 600;
    font-size: 16px !important;
    color: #000000 !important;
    background-color: #00df9a;
    padding: 8px 16px;
    border: solid #00df9a 1px;
    box-shadow: rgb(0, 0, 0) -50px -50px 0px -50px;
    border-radius: 50px;
    transition: 696ms;
    transform: translateY(0);
    cursor: pointer;
    text-align: center;
    // margin: auto;
    line-height: 18px;
}

.btn-sec:hover {

    transition: 696ms;
    transform: translateY(-0px);
    background-color: #000000;
    color: #FFFFFF !important;
    font-weight: 600;
    transition: all 0.2s;
}